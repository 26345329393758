<!--
    组件说明: 带数据上传的表单
           propertys:       value(必须）所有配置参数options
                            id         需要绑定id,用来初始化数据
                            title      清单抬头标题
           options具体参数:  getDetailUrl 打开时请求数据地址(string/function)
                            items        清单具体各项配置
-->
<template>
    <el-descriptions :title="title?title:'基本信息'" direction="horizontal" column="2" border>

        <template v-for="item in items">
            <el-descriptions-item v-if="item.type === Search_Type.ProvinceCity" :label="item.alias?item.alias:item.label">
                {{panelForm[item.name.replace(',','')]}}
            </el-descriptions-item>
            <el-descriptions-item v-else-if="item.type === Search_Type.Select" :label="item.alias?item.alias:item.label">
                {{item.props?item.props[panelForm[item.name]]:''}}
            </el-descriptions-item>
            <el-descriptions-item v-else :label="item.alias?item.alias:item.label">
                <template v-if="item.name?item.name.indexOf('.')>0:false">
                  {{panelForm[item.name.split('.')[0]]?panelForm[item.name.split('.')[0]][item.name.split('.')[1]]:''}}
                </template>
                <template v-else>
                  {{panelForm[item.name]}}
                </template>
            </el-descriptions-item>
        </template>
    </el-descriptions>
</template>

<script>
    import {Search_Type} from "../../constants";
    export default {
        name: 'FilmDcmp_Detail',
        props: {
            value: {
                type: Object,
                required: true
            },
            title:{
                type: String,
                required: false,
            },
            id: {
                type: String,
                required: false
            },
        },
        data(){
            return {
                panelForm: {},
                Search_Type: Search_Type,
                initOnce: false,
            }
        },
        methods:{
            loadFormData(){
                if(this.initData){
                    this.panelForm = this.initData
                    return;
                }
                if((!this.initOnce&&this.getDetailUrl) || (this.getDetailUrl&&this.id)){
                    this.initOnce = true;
                    // this.loadDynamicSelectData(this.id)
                    let url = this.concatUrl(this.getDetailUrl, this.id)
                    if(!url){
                        this.$message.error("加载数据失败");
                        return ;
                    }
                    this.$axios.get(url).then(res=>{
                        this.panelForm = res.data;
                        this.items.filter(item=>item.type===Search_Type.ProvinceCity).forEach(item=>{
                            let names = item.name.split(',')
                            this.panelForm[item.name.replace(',', '')] = this.g_getProvinceCityName(this.panelForm[names[0]], this.panelForm[names[1]])
                        })
                    })
                }else{
                    this.panelForm = {}
                }
            },
            concatUrl(urlObject, id){
                let url = null;
                if(typeof(urlObject) === 'string'){
                    if(id){
                        if(urlObject.indexOf('{id}') !== -1){
                            url = urlObject.replace('{id}', id)
                        }else{
                            url = `${urlObject}`
                        }
                    }else{
                        url = urlObject
                    }
                }else if(typeof(urlObject) === 'function'){
                    if(id){
                        url = urlObject(id)
                    }else{
                        url = urlObject()
                    }
                }
                return url
            },
        },
        computed:{
            options: {
                get(){
                    return this.value;
                },
                set(val){
                    this.$emit('input', val)
                }
            },
            getDetailUrl: {
                get(){
                    return this.options.getDetailUrl;
                }
            },
            items: {
                get(){
                    return this.options.items;
                }
            },
            initData: {
                get(){
                    return this.options.initData;
                }
            }
        },
        watch:{
            id:{
                deep: true,
                handler: function (newVal, oldVal) {
                    // console.log('watch')
                    this.loadFormData();
                }
            }
        },
        mounted: function () {
            this.$nextTick(function () {
                // 仅在整个视图都被渲染之后才会运行的代码
                // console.log('mounted')
                this.loadFormData();
            })
        },

    }
</script>

<style scoped>

</style>